import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src750413537/src/src/layout/defaultLayout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Section = makeShortcode("Section");
const Grid = makeShortcode("Grid");
const Box = makeShortcode("Box");
const Icon = makeShortcode("Icon");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Section mdxType="Section">
      <h1 {...{
        "id": "logo-use"
      }}>{`Logo Use`}</h1>
      <Grid width="60rem" gridTemplateColumns={['1fr', '1fr 1fr', '1fr 1fr 1fr']} gridTemplateRows="10rem 10rem" gridGap={2} my={4} mdxType="Grid">
  <Box justifyContent="center" alignItems="center" bg="blueLight" mdxType="Box">
    <Icon color="black" height="8rem" name="rebel-logo-white" width="15rem" mdxType="Icon" />
  </Box>
  <Box justifyContent="center" alignItems="center" bg="blueDark" mdxType="Box">
    <Icon color="white" height="8rem" name="rebel-logo-white" width="15rem" mdxType="Icon" />
  </Box>
  <Box justifyContent="center" alignItems="center" bg="white" mdxType="Box">
    <Icon color="black" height="8rem" name="rebel-logo-white" width="15rem" mdxType="Icon" />
  </Box>
  <Box justifyContent="center" alignItems="center" bg="blueLight" mdxType="Box">
    <Icon color="black" height="8rem" name="rebel-pin" width="6rem" mdxType="Icon" />
  </Box>
  <Box justifyContent="center" alignItems="center" bg="blueDark" mdxType="Box">
    <Icon color="white" height="8em" name="rebel-pin" width="6rem" mdxType="Icon" />
  </Box>
  <Box justifyContent="center" alignItems="center" bg="white" mdxType="Box">
    <Icon height="8rem" name="rebel-logo-color" width="15rem" mdxType="Icon" />
  </Box>
      </Grid>
      <p>{`When you have a white background, the black Rebel logo is preferred.`}</p>
      <p>{`When you have a dark coloured background, use the white Rebel logo.`}</p>
      <p>{`When you have a light coloured background, use the black Rebel logo.`}</p>
      <p>{`Please contact `}<a parentName="p" {...{
          "href": "mailto:Martin@rebel.com"
        }}>{`Martin@rebel.com`}</a>{` for guideance on using the black and orange version of the Rebel logo and the Rebel.com version of the logo - these are only used in special cases.`}</p>
    </Section>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      